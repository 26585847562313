import { html, unsafeHTML, css, formCss } from 'Components';
import DrawerForm from 'Components/abstract/DrawerForm.js';

import nodeTypes from './types.js';
import 'Components/business/select-validation-email-template.js';
import 'Components/business/select-validation-period-interval.js';
import 'Components/business/select-validation-sms-template.js';
import 'Components/business/select-gender.js';
import 'Components/business/select-human-role.js';
import 'Components/business/select-permissions.js';
import 'Components/business/select-io-roles.js';
import { Notify } from 'Utils';

class CustomerNodeEdit extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        .marginbottom {
          margin-bottom:10px;
        }

        .bold {
          font-weight: bold;
        }

        sl-tab {
          position:relative;
        }

        sl-tab m-icon {
          position: absolute;
          right: -4px;
          top: 5px;
        }
      `
    ]
  }

  static get properties() {
    return {
      item: { type: Object }
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
          },
        },
        french:{
          translation: {
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/admin/customers/tree';
    this.urlVar = 'idn';
    this.eventUpdated = 'customer-node-updated';
    this.itemTitleField = 'name';
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('language-changed', this.onLanguageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this.onLanguageChanged);
  }

  onLanguageChanged() {
    this.nodeTypes = nodeTypes.getNodeTypes();
    this.requestUpdate();
  }

  firstUpdated() {
    super.firstUpdated();
    this.nodeTypes = nodeTypes.getNodeTypes();
  }

  getNodeTypeName(nodeType) {
    return this.nodeTypes[nodeType].name;
  }
  
  getParentName(item) {
    return item.parentId ? this.parent.items.find(i => i._id === item.parentId).name : '';
  }

  getItemTitle(opts = {}) {
    const nodeTypeName = this.getNodeTypeName(this.item.nodeType);
    if (opts.mode === 'add') {
      if (!this.item.parentId) {
        return `${nodeTypeName}`
      } else {
        return `${nodeTypeName} dans ${this.getParentName(this.item)}`
      }
    } else {
      if (this.nodeTypes[this.item.nodeType].treeDisplayFields) {
        const title = this.nodeTypes[this.item.nodeType].treeDisplayFields.map(f => this.item[f]).join(' ');
        return `${nodeTypeName} ${title}`
      } else {
        return `${nodeTypeName} ${this.item.name ? `/ ${this.item.name}` : ''}`
      }
    }
  }

  handleSubmitError(input, response) {
    this._log.debug('handleSubmitError', input, response);
    if (!input) {
      console.warn('Field not found', response.field);
      return;
    }

    let msg = 'Valeur obligatoire';
    if (response.reason === 'MIN_LENGTH') {
      msg = `Longueur minimum de ${response.minValue} caractères`;
    } else if (response.reason === 'REQUIRED') {
      msg = 'Ce champ est obligatoire';
    } else if (response.reason === 'INVALID_FORMAT') {
      msg = 'Ce champ est invalide';
    } else if (response.reason === 'INVALID') {
      if (response.field === 'nodeType') {
        msg = 'Type de noeud non implémenté (API)';
      } else {
        msg = `Valeur invalide (${response.field})`;
      }
    } else if (response.reason === 'KC_ACCOUNT_EXISTS') {
      msg = 'Un compte Keycloak existe déjà avec cet email';
    } else if (response.reason === 'KC_GROUP_EXISTS') {
      msg = 'Un groupe existe déjà avec ce nom';
    } else if (response.reason === 'KC_GROUP_NOT_FOUND') {
      msg = 'Groupe introuvable';
    } else if (response.reason === 'KC_USER_NOT_FOUND') {
      msg = 'Utilisateur introuvable';
    } else {
      msg = 'Erreur de validation inconnue: '+response.reason;
    }

    if (input.setCustomValidity) {
      input.setCustomValidity(msg);
      input.reportValidity();
    }
    
    if (input.hidden || input.getAttribute('type') === 'hidden' || input.className.includes('hidden')) {
      // hidden field
      Notify.error(msg);
    }
  }

  renderField(field, tab) {
    
    let component = '';

    switch (field.component) {

      case 'sl-input':
        component = `<sl-input 
          class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
          ${field.style ? `style="${field.style}"` : ''}
          size="small" 
          name="${field.name}"
          spellcheck="false"
          defaultValue="${field.sameDefaultValue ? this.item[field.name] : field.defaultValue || ''}"
          value="${this.item[field.name] || field.defaultValue || ''}"
          placeholder="${field.placeholder || ''}"
          help-text="${field.help || ''}"
          ${field.type ? `type="${field.type}"` : ''}
          label="${field.label}">
        </sl-input>`;
        break;

      case 'sl-textarea':
        component = `<sl-textarea 
          class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
          ${field.style ? `style="${field.style}"` : ''}
          size="small" 
          spellcheck="false"
          name="${field.name}" 
          defaultValue="${field.sameDefaultValue ? this.item[field.name] : ''}"
          value="${this.item[field.name] || field.defaultValue || ''}"
          help-text="${field.help || ''}"
          rows="${field.rows}"
          label="${field.label}">${this.item[field.name]}</sl-textarea>`;
          break;

      case 'sl-switch':
        component = `<sl-switch 
          class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
          ${field.style ? `style="${field.style}"` : ''}
          size="small" 
          name="${field.name}" 
          value="${this.item[field.name] || field.defaultValue || ''}"
          ?checked="${this.item[field.name]}" 
          >${field.label}</sl-switch><br/>`;
          break;

      case 'section-header':
        component = `<section-header 
          class="${field.bold ? 'bold' : ''}"
          ${field.variant ? `variant="${field.variant}"` : ''}
          ${field.style ? `style="${field.style}"` : ''}
          size="${field.size || 'normal'}" 
          >${field.label}</section-header><br/>`;
        break;

      case 'box-styled':
        component = `<box-styled 
          class="${field.bold ? 'bold' : ''}"
          ${field.variant ? `variant="${field.variant}"` : ''}
          ${field.style ? `style="${field.style}"` : ''}
          size="${field.size || 'normal'}" 
          >${field.label}</box-styled><br/>`;
        break;

      case 'select-validation-email-template':
        component = `<select-validation-email-template
          class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
          ${field.style ? `style="${field.style}"` : ''}
          name="${field.name}"
          label="${field.label}"
          search
          size="small"
          sort="name"
          value="${this.item[field.name] || ''}"
          ></select-validation-email-template>`;
        break;
      
      case 'select-validation-sms-template':
        component = `<select-validation-sms-template
          class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
          ${field.style ? `style="${field.style}"` : ''}
          name="${field.name}"
          label="${field.label}"
          search
          size="small"
          sort="name"
          value="${this.item[field.name] || ''}"
          ></select-validation-sms-template>`;
        break;
      
      case 'select-validation-period-interval':
        component = `<select-validation-period-interval
          class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
          ${field.style ? `style="${field.style}"` : ''}
          name="${field.name}"
          label="${field.label}"
          size="small"
          value="${this.item[field.name] || ''}"
          ></select-validation-period-interval>`;
        break;

      case 'select-gender':
        component = `<select-gender
          class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
          ${field.style ? `style="${field.style}"` : ''}
          name="${field.name}"
          label="${field.label}"
          size="small"
          value="${this.item[field.name] || ''}"
          defaultValue="${field.defaultValue || ''}"
          ></select-gender>`;
        break;

      case 'select-human-role':
        component = `<select-human-role
          class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
          ${field.style ? `style="${field.style}"` : ''}
          name="${field.name}"
          label="${field.label}"
          size="small"
          clearable
          value="${this.item[field.name] || ''}"
          ></select-human-role>`;
        break;
      
      case 'select-permissions':
        component = `<select-permissions
          class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
          ${field.style ? `style="${field.style}"` : ''}
          name="${field.name}"
          label="${field.label}"
          size="small"
          value="${this.item[field.name] || ''}"
          merged="${this.item.permissions_merged ? this.item.permissions_merged[field.name] || '' : ''}"
          ></select-permissions>`;
        break;

      case 'select-io-roles':
        component = `<select-io-roles 
          class="labol marginbottom ${tab.css || ''} ${field.css || ''}" 
          ${field.style ? `style="${field.style}"` : ''}
          name="${field.name}"
          label="${field.label}"
          size="small"
          value="${this.item[field.name] || ''}"
          merged="${this.item.permissions_merged ? this.item.permissions_merged[field.name] || '' : ''}"
          ></select-io-roles>`;
        break;

      case 'box-pre':
        component = `<box-pre>${JSON.stringify(this.item, null, 4)}</box-pre>`;
        break;

      case 'box-json':
        component = `<box-json>${JSON.stringify(this.item, null, 4)}</box-json>`;
        break;

      case 'blank':
        component = '<br/>';
        break;
    }

    // if we don't use unsafeHTML, there is some problem passing parameters, like style or input type ..    
    return unsafeHTML(component);

    //return component;
    
  }

  handleTestValidation() {
    this.compareFields();
  }

  renderForm() {
        
    if (!this.item) return '';

    const tabs = this.nodeTypes[this.item.nodeType].tabs;

    if (!tabs || !tabs.length) {
      return html`
        <box-styled variant="warning">
          <p>Node type &laquo;<b> ${this.item.nodeType} </b>&raquo; not yet implemented</p>
        </box-styled>`;
    }

    return html`
      <form>
        <tab-group level="t" .item=${this.item}>
          ${tabs.map(tab => {
            if (tab.hidden && tab.hidden()) return;
            return html`
              <sl-tab slot="nav" panel="${tab.name}">${unsafeHTML(tab.title)}</sl-tab>
              <sl-tab-panel name="${tab.name}">
                  ${tab.fields_hidden?.map(field => this.renderField(field, tab))}
                <scrollable-component scrollbar-visibility="always">
                  ${tab.fields_lgs?.length 
                    ? html`
                        <tab-group-lg level="lg" .item=${this.item} .getTranslatePayload=${this.getTranslatePayload} @translated=${this.onTranslated}>
                          <br/>
                          ${tab.fields_lgs?.map(field => this.renderField(field, tab))}
                        </tab-group-lg>
                      `
                    : ''
                  }
                  ${tab.fields.map(field => this.renderField(field, tab))}
                </scrollable-component>
              </sl-tab-panel>
            `
          })}
        </tab-group>
      </form>
    `;
  }

  render() {    
    return html`
      <modal-drawer name="modal-node-edit" modal>
        <style type="text/css">${this.constructor.styles}</style>
        ${this.renderForm()}
        <sl-button slot="bt1" variant="primary" @click="${this.handleSubmit}">${this._tl('save')}</sl-button>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('cancel')}</sl-button>
      </modal-drawer>

    `;
  }

}

customElements.define('customer-node-edit', CustomerNodeEdit);

