import { PageElement, html, css } from 'Components';
import { Session } from 'Utils';
import './tree.js';
import 'Components/business/keycloak-browser';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
        }

        m-icon {
          font-size:2em;
        }

        m-icon[name="cached"] {
          color:var(--sl-color-gray-400);
        }

        m-icon[name="cached"]:hover {
          color:var(--sl-color-primary-500);
        }

        sl-button::part(spinner) {
          font-size:1.5em;
        }
      `
    ];
  }

  static get properties() {
    return {
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            refresh:'Refresh',
            add:'Add',
            kcbrowser:'Keycloak Browser (admin only)'
          },
        },
        french:{
          translation: {
            refresh:'Rafraîchir',
            add:'Ajouter',
            kcbrowser:'Keycloak browser (administrateur uniquement)'
          }
        }
      }
    ]
  }

  async refresh() {
    this.shadowRoot.querySelector('sl-button[name="refresh"]').loading = true;
    await this.shadowRoot.querySelector('customers-tree').reload();
    this.shadowRoot.querySelector('sl-button[name="refresh"]').loading = false;
  }


  showHelp() {
    this.shadowRoot.querySelector('modal-drawer[name="help"]').show();
  }

  showKcBrowser() {
    this.shadowRoot.querySelector('modal-drawer[name="kcbrowser"]').show();
  }

  render() {    
    return html`
      <section-header micon="account_tree" backroute="../">
        Groupes & utilisateurs
        <sl-button slot="right" variant="text" size="small" name="refresh" title="${this._tl('refresh')}" @click="${this.refresh}"><m-icon name="cached"></sl-button>
        ${Session.isSoc()
          ? html`<sl-button slot="right" variant="text" size="small" name="kcbrowser" title="${this._tl('kcbrowser')}" @click="${this.showKcBrowser}"><m-icon name="communities"></sl-button>`
          : ''
        }
        <sl-button slot="right" variant="text" size="small" name="help" @click="${this.showHelp}"><m-icon name="help"></sl-button>
      </section-header>
      <br/>
      <customers-tree></customers-tree>

      <modal-drawer name="help" label="Aide">
        <p>
          <sl-details summary="Interactions avec keycloak">
            A documenter
          </sl-details>
          <br/>
          
          <sl-details summary="Mécanisme de propagation">
            A documenter (permissions + autre)
          </sl-details>
          <br/>
          
          <sl-details summary="Raccourcis clavier">
            Touche "+" : déplie le noeud sélectionné<br/>
            Touche "-" : replie le noeud selectionné<br/>
            Touche "*" : déplie/replie tous les noeuds et ses enfants du noeud sélectionné<br/>
          </sl-details>
        </p>
      </modal-drawer>

      <modal-drawer name="kcbrowser" label="Keycloak Browser">
        <keycloak-browser></keycloak-browser>
      </modal-drawer>

      <customer-node-edit></customer-node-edit>
      
      <br/><br/><br/>
    `;
  }

}

customElements.define('page-admin-customers', Page);